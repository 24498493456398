import React, {useState} from "react"
import { Link, graphql } from "gatsby"
import { Spring } from "react-spring/renderprops";
import { OutboundLink } from "gatsby-plugin-google-gtag"
import VisibilitySensor from '../components/visibilitySensor'
import PopupForm from '../components/popupform'
import HelpForm from "../components/helpform"
import Layout from "../layouts/layout"
import SEO from "../components/seo"

const PricingPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    if (isPopupOpen) {
      setIsPopupOpen(false);
    } else {
      setIsPopupOpen(true);
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    togglePopup();
    typeof window !== "undefined" && window.gtag("event", "click", {   
        'event_category': "Get Started",
        'event_label': "Demo Request",
        'value': 1 
      })
  }

    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="The Sunset Health Pricing Plan" />
        <PopupForm isPopupOpen={isPopupOpen} onClickPopup={togglePopup} />
        <section className="uni-section about-hero">
            <div className="uni-container flex-space-between flex-vertical-reverse">
                <div className="uni-col">
                    <h1 className="uni-h1">Health is the best investment</h1>
                    <p className="uni-hero-p">
                        Create a healthier, happier, and more productive 
                        workforce with Sunset Health’s industry leading burnout platform.
                    </p>
                    <button className="uni-cta-btn btn" onClick={handleClick}>Get Started</button>
                </div>
                <img src={require('../../content/assets/hero-content/pricing.svg')} alt="Pricing image" className="uni-hero_img pricing_img" />
            </div>
        </section>
        <section className="uni-section bg-f0f6f9">
            <div className="uni-container">
                <h3 className="uni-h3">Pricing:</h3>
                <div className="ind-pricing-wrap bg-fff">
                    <VisibilitySensor partialVisibility once>
                        {({ isVisible }) => (
                            <Spring delay={300} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                                {({opacity, transform}) => (
                                    <div className="ind-pricing-col" style={{opacity}}>
                                        <div className="ind-pricing-choice-wrapper">
                                            <div className="ind-pricing-choice">Free</div>
                                            <p className="ind-pricing-description">All the tools you will need to begin your journey to better handle stress.</p>
                                        </div>
                                        <h5 className="ind-pricing-wrapper">
                                            <span className="ind-price">$0</span><br/>
                                            <span className="ind-price-unit">/month</span>
                                        </h5>
                                        <OutboundLink href="http://customer.sunset.health/" className="uni-cta-btn pricing-cta btn">SIGN UP NOW</OutboundLink>
                                        <div className="ind-pricing-features">
                                            <p className="ind-pricing-feature-title">Free for individuals:</p>
                                            <ul className="ind-pricing-feature-list">
                                                <li>Track daily mood</li>
                                                <li>Get 1 daily, customized task to complete</li>
                                                <li>Set up snooze notifications to get reminders in morning and night</li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </Spring>
                        )}
                    </VisibilitySensor>
                    <VisibilitySensor partialVisibility>
                        {({ isVisible }) => (
                            <Spring delay={500} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                                {({opacity, transform}) => (
                                    <div className="ind-pricing-col" style={{opacity}}>
                                        <div className="ind-pricing-choice-wrapper">
                                            <div className="ind-pricing-choice">Premium</div>
                                            <p className="ind-pricing-description">Advanced features for users to get a in-depth understanding of how to better manage burnout.</p>
                                        </div>
                                        <h5 className="ind-pricing-wrapper">
                                            <span className="ind-price">$29.99</span><br/>
                                            <span className="ind-price-unit">/month</span>
                                        </h5>
                                        <OutboundLink href="http://customer.sunset.health/" className="uni-cta-btn pricing-cta btn">SIGN UP NOW</OutboundLink>
                                        <div className="ind-pricing-features">
                                            <p className="ind-pricing-feature-title">Everything in Free, plus:</p>
                                            <ul className="ind-pricing-feature-list">
                                                <li>Access to all 3-4 daily tasks to complete</li>
                                                <li>Access to daily insights and moods to see what tasks are helping</li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </Spring>
                        )}
                    </VisibilitySensor>   
                    <VisibilitySensor partialVisibility>
                        {({ isVisible }) => (
                            <Spring delay={700} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                                {({opacity, transform}) => (             
                                    <div className="ind-pricing-col" style={{opacity}}>
                                        <div className="ind-pricing-choice-wrapper pricing-corporate">
                                            <div className="ind-pricing-choice">Corporate</div>
                                            <p className="ind-pricing-description">For teams and employers to help offer a solution to handle stress and burnout in the workplace.</p>
                                        </div>
                                        <button className="uni-cta-btn pricing-cta btn" onClick={handleClick}>CONTACT SALES</button>
                                        <div className="ind-pricing-features">
                                            <p className="ind-pricing-feature-title">Everything in Premium, plus:</p>
                                            <ul className="ind-pricing-feature-list">
                                                <li>Dynamic features and appearance of the app to fit your company needs</li>
                                                <li>Detailed insight reporting to visualize what is helping your employees</li>
                                                <li>Customizable plans better suited for your employees</li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </Spring>
                        )}
                    </VisibilitySensor>  
                </div>
            </div>
        </section>
        <section className="uni-section">
            <div className="uni-container">
                <h3 className="uni-h3">FAQ:</h3>
                <div className="ind-faq-wrap">
                    <div className="ind-faq-row">
                        <div className="ind-faq-question-wrapper">
                            <p className="ind-question">Can I purchase a subscription after I sign up on the mobile app?</p>
                        </div>
                        <div className="ind-faq-answer">
                            <p>
                                Generally. From time to time, Sunset Health may offer products and services for 
                                purchase (“in app purchases”) through iTunes, carrier billing, Sunset Health direct 
                                billing or other payment platforms authorized by Sunset Health. If you choose to 
                                make an in app purchase, you will be prompted to confirm your purchase with the 
                                applicable payment provider, and your method of payment (be it your card or a third 
                                party account such as iTunes) (your “Payment Method”) will be charged at the prices 
                                displayed to you for the service(s) you’ve selected as well as any sales or similar 
                                taxes that may be imposed on your payments, and you authorize Sunset Health or the 
                                third party account, as applicable, to charge you.
                            </p>
                        </div>
                    </div>
                    <div className="ind-faq-row">
                        <div className="ind-faq-question-wrapper">
                            <p className="ind-question">Will my subscription auto-renew?</p>
                        </div>
                        <div className="ind-faq-answer">
                            <p>
                                If you purchase an auto-recurring periodic subscription, your Payment Method will 
                                continue to be billed for the subscription until you cancel. After your initial 
                                subscription commitment period, and again after any subsequent subscription period, 
                                your subscription will automatically continue for an additional equivalent period, 
                                at the price you agreed to when subscribing. If you do not wish your subscription 
                                to renew automatically, or if you want to change or terminate your subscription, 
                                you will need to log in to your third party account and follow instructions to 
                                cancel your subscription, even if you have otherwise deleted your account with 
                                us or if you have deleted the Sunset Health application from your device. Deleting 
                                your account on Sunset Health or deleting the Sunset Health application from your 
                                device does not cancel your subscription; Sunset Health will retain all funds charged 
                                to your Payment Method until you cancel your subscription on Sunset Health or the third 
                                party account, as applicable. If you cancel your subscription, you may use your subscription 
                                until the end of your then-current subscription term, and your subscription will not be renewed 
                                after your then-current term expires.
                            </p>
                            <p>
                                Additional Terms that apply if you pay Sunset Health directly with your Payment Method. 
                                If you pay Sunset Health directly, Sunset Health may correct any billing errors or mistakes 
                                that it makes even if it has already requested or received payment. If you initiate a chargeback 
                                or otherwise reverse a payment made with your Payment Method, Sunset Health may terminate your 
                                account immediately in its sole discretion.
                            </p>
                        </div>
                    </div>
                    <div className="ind-faq-row">
                        <div className="ind-faq-question-wrapper">
                            <p className="ind-question">It is possible to refund my subscription?</p>
                        </div>
                        <div className="ind-faq-answer">
                            <p>
                                Generally, all charges for purchases are nonrefundable, and there are no refunds or credits 
                                for partially used periods. We may make an exception if a refund for a subscription offering 
                                is requested within fourteen days of the transaction date, or if the laws applicable in your 
                                jurisdiction provide for refunds.
                            </p>
                        </div>
                    </div>
                    <div className="ind-faq-row">
                        <div className="ind-faq-question-wrapper">
                            <p className="ind-question">How do I request a refund for my subscription?</p>
                        </div>
                        <div className="ind-faq-answer">
                            <p>
                                You may cancel your subscription, without penalty or obligation, at any time prior to midnight of the 
                                third business day following the date you subscribed. You can cancel your subscription through the specific 
                                app store in which you originally downloaded the app if you made your subscription through there. Else, you 
                                can send us an email request to cancel your payment if it was made through our web portal.
                            </p>
                            <p>
                                For subscribers residing in Arizona, California, Connecticut, Illinois, Iowa, Minnesota, New York, 
                                North Carolina, Ohio and Wisconsin, the terms below apply:
                            </p>
                            <p>
                                You may cancel your subscription, without penalty or obligation, at any time prior to midnight of the 
                                third business day following the date you subscribed. In the event that you die before the end of your 
                                subscription period, your estate shall be entitled to a refund of that portion of any payment you had 
                                made for your subscription which is allocable to the period after your death. In the event that you 
                                become disabled (such that you are unable to use the services of Sunset Health) before the end of your 
                                subscription period, you shall be entitled to a refund of that portion of any payment you had made for 
                                your subscription which is allocable to the period after your disability by providing the company notice 
                                in the same manner as you request a refund as described below.
                            </p>
                        </div>
                    </div>
                    <div className="ind-faq-row">
                        <div className="ind-faq-question-wrapper">
                            <p className="ind-question">How can I cancel my subscription?</p>
                        </div>
                        <div className="ind-faq-answer">
                            <p>
                                If you subscribed using your Apple ID, refunds are handled by Apple, not Sunset Health. To 
                                request a refund, go to iTunes, click on your Apple ID, select “Purchase history,” find the 
                                transaction and hit “Report Problem”. You can also submit a request at //getsupport.apple.com.
                            </p>
                        </div>
                    </div>
                    <div className="ind-faq-row">
                        <div className="ind-faq-question-wrapper">
                            <p className="ind-question">If I have issues with my subscription, who do I contact?</p>
                        </div>
                        <div className="ind-faq-answer">
                            <p>You can contact us via the following details: support@sunset.health</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="uni-section pricing-request">
            <div className="uni-container">
                <div className="ind-get-in-touch-wrap">
                    <div className="ind-get-in-touch-inner">
                        <div className="get-in-touch-image">
                            <img src={require('../../content/assets/splash/request-1.jpg')} />
                        </div>
                        <div className="ind-get-in-copy bg-fdf3ee">
                            <h3>Your journey begins now with Sunset Health</h3>
                            <button className="uni-cta-btn btn" onClick={handleClick}>Request a Demo</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </Layout>
    )
}

export default PricingPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
